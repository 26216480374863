export async function loadOrderFlow(resume = false, postalData = {}, productData = {}) {
    const {
        registerOrderFlow,
        resumeOrderFlow,
    } = await import('../../../templates/shared/orderFlow/orderFlow.ts');
    if (resume) {
        resumeOrderFlow();
    } else {
        registerOrderFlow(postalData, productData, false);
    }
}

export async function loadSignupFlow(productData) {
    const {
        registerSignupFlow,
    } = await import('../../../templates/shared/orderFlow/orderFlow.ts');
    registerSignupFlow(productData);
}

export function registerOrderFlowButtons() {
    document.querySelectorAll('[data-trigger-orderFlow]').forEach((el) => {
        el.addEventListener('click', (event) => {
            event.preventDefault();
            loadOrderFlow(false, {}, el.dataset);
        });
    });
}

function onPageShowHandler(e) {
    // Check if we need to resume the flow
    if (window.location.hash !== '#/aanbiedingen' && window.location.hash !== '#/prijsopbouw') {
        return;
    }
    const resume = JSON.parse(window.sessionStorage.getItem('resumeOrderFlow'));
    if (e.persisted && resume) {
        loadOrderFlow(resume)
            .then(() => {
                window.sessionStorage.setItem('resumeOrderFlow', 'false');
            });
    }
    // Remove the event listener after handling it once
}
export function tryResumeOrderFlow() {
// Add the event listener only if it hasn't been added before

    if (!window.pageShowHandlerAdded) {
        window.addEventListener('pageshow', onPageShowHandler);
        window.pageShowHandlerAdded = true;
    }
}
